<template>
  <div id="petwizard-view ">
    <b-modal
      id="petwizard-modal"
      class="modal-dialog modal-dialog-centered modal-lg"
      scrollable
      ref="my-modal"
      hide-footer
      size="lg"
      @shown="checkType"
      @hidden="resetAll"
    >
      <div style="min-height: 260px !important">
        <b-overlay :show="loader" class="my-4">
          <section>
            <select-pet
              v-bind:pets="pets"
              v-if="petList"
              v-on:petToContinue="petToContinue"
              v-on:petToSpecies="petToSpecies"
            ></select-pet>
            <selectpet-occasion
              v-bind:species="species"
              v-if="showOccasion"
              v-bind:petData="petData"
              v-on:ocasionToName="ocasionToName"
              v-on:ocasionToImage="ocasionToImage"
              v-on:ocasionToPrice="ocasionToPrice"
            ></selectpet-occasion>
            <select-species
              v-if="showSpecies"
              v-on:speciesToBreed="speciesToBreed"
            ></select-species>
            <select-breed
              v-if="showBreed"
              v-bind:species="species"
              v-on:breedTospecies="breedTospecies"
              v-on:breedToGender="breedToGender"
            ></select-breed>
            <select-gender
              v-if="showGender"
              v-on:genderToBreed="genderToBreed"
              v-on:genderToAgeRange="genderToAgeRange"
            ></select-gender>
            <age-range
              v-if="showAge"
              v-on:agerangeToName="agerangeToName"
              v-on:ageRangeToGender="ageRangeToGender"
            ></age-range>
            <!-- <select-weight
              v-if="showWeight"
              v-on:weightToAge="weightToAge"
              v-on:weightToName="weightToName"
            ></select-weight> -->
            <enter-name
              v-if="showName"
              v-on:nameToAge="nameToAge"
              v-on:nameToOccasion="nameToOccasion"
            ></enter-name>
            <add-image
              v-if="showImage"
              v-on:ImageToOccasion="ImageToOccasion"
              v-on:ImageToPrice="ImageToPrice"
            ></add-image>
            <!-- <add-preference
              v-if="showPreferance"
              v-on:preferenceToImage="preferenceToImage"
              v-on:preferenceToPrice="preferenceToPrice"
            ></add-preference> -->
            <price-range
              v-bind:menu="menu"
              v-if="SelectPricerange"
              v-on:priceToPreference="priceToPreference"
              v-on:priceToproducts="priceToproducts"
            ></price-range>
            <select-continue
              v-if="showContinue"
              v-bind:name="name"
              v-bind:petData="petData"
              v-on:continueToOccasion="continueToOccasion"
              v-on:continueToPet="continueToPet"
            ></select-continue>

            <div v-if="!showOccasion" class="px-4 py-1">
              <p class="mb-0">You've Selected</p>
            </div>
            <div class="px-4 mb-4 py-2">
              <strong class="text-dark mt-1" v-if="species != null">
                {{ species.pet_name }} |
              </strong>
              <strong class="text-dark mt-1" v-if="breed != null">
                {{ breed.breed_name }} |
              </strong>
              <strong class="text-dark mt-1" v-if="gender != null">
                {{ gender }} |
              </strong>
              <strong class="text-dark mt-1" v-if="age != null">
                {{ age.age_label }} |
              </strong>
              <strong class="text-dark mt-1" v-if="name != null">
                {{ name }} |
              </strong>
              <strong class="text-dark mt-1" v-if="petoccasion != null">
                {{ petoccasion.occasion_name }} |
              </strong>
            </div>
          </section>
          <template #overlay>
            <div class="text-center">
              <img
                src="@/assets/site/images/Govava_Wait_icon.gif"
                alt=""
                class="my-4"
              />
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SelectpetOccasion from "@/views/site/petwizard/SelectpetOccasion";
import SelectSpecies from "@/views/site/petwizard/SelectSpecies";
import SelectBreed from "@/views/site/petwizard/SelectBreed";
import SelectGender from "@/views/site/petwizard/SelectGender";
import AgeRange from "@/views/site/petwizard/AgeRange";
import SelectWeight from "@/views/site/petwizard/SelectWeight";
import EnterName from "@/views/site/petwizard/EnterName";
import AddImage from "@/views/site/petwizard/AddImage";
import AddPreference from "@/views/site/petwizard/AddPreference";
import SelectPet from "@/views/site/petwizard/SelectPet";
import PriceRange from "@/views/site/petwizard/PriceRange";
import SelectContinue from "@/views/site/petwizard/selectContinue";

export default {
  name: "PetwizardView",
  props: ["menu", "isMenu"],
  data() {
    return {
      errors: [],
      error_msg: null,
      loader: false,
      petoccasion: null,
      age: null,
      gender: null,
      species: null,
      breed: null,
      weight: null,
      name: null,
      selected_preference: null,
      image: null,
      price: null,
      pets: [],
      petList: false,
      perice: null,
      petData: null,
      petId: null,
      pet_id: null,
      showAge: false,
      showOccasion: false,
      showGender: false,
      showSpecies: false,
      showBreed: false,
      SelectPricerange: false,
      showWeight: false,
      showName: false,
      showImage: false,
      showPreferance: false,
      showContinue: false,
    };
  },
  components: {
    SelectpetOccasion,
    SelectSpecies,
    SelectBreed,
    SelectGender,
    AgeRange,
    SelectWeight,
    EnterName,
    AddImage,
    AddPreference,
    SelectPet,
    PriceRange,
    SelectContinue,
  },
  methods: {
    checkType() {
      if (this.$store.state.petWizData && this.$store.state.comeFromRedo) {
        const petWizData = this.$store.state.petWizData;

        this.petoccasion = petWizData.petoccasion;
        this.age = petWizData.age;
        this.gender = petWizData.gender;
        this.species = petWizData.species;
        this.breed = petWizData.breed;
        this.weight = petWizData.weight;
        this.name = petWizData.name;
        this.image = petWizData.image;
        this.price = petWizData.price;
        this.pets = petWizData.pets;
        this.petId = petWizData.petId;
        this.pet_id = petWizData.pet_id;
        this.SelectPricerange = true;
      } else this.checkPets();
    },
    SetPetData() {
      this.species = this.petData.type;
      this.breed = this.petData.breed;
      this.gender = this.petData.gender;
      this.age = this.petData.ageRange;
      this.weight = this.petData.weight;
      this.name = this.petData.name;
      // this.petoccasion = this.petData.occasion;
      this.petId = this.petData.pet_id;
    },
    checkPets() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/pet/getPetwizardDetails";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: userdata_array.user.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.petwizardDetails.length == 0) {
            this.showSpecies = true;
          } else {
            this.pets = data.petwizardDetails;
            this.petList = true;
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    Products() {
      let petId = "";
      var name = this.name;
      var occasion_name = this.petoccasion.id;
      if (this.image == null) {
        var image = null;
      } else {
        var image = this.image;
      }
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/products/getWizardPetProducts";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          occasion: this.petoccasion.id,
          breed: this.breed.id,
          gender: this.gender,
          priceRange_from_price: this.price.from_price,
          priceRange_to_price: this.price.to_price,
          type: this.species.id,
          ageRange: this.age.id,
          image: image,
          weight: this.weight,
          name: this.name,
          user_id: userdata_array.user.id,
          access_type: "web",
          pet_id: this.petId,
          countryCode: countryCode,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const id = Math.random()
            .toString(36)
            .slice(2);
          petId = data.petDetails.id;
          this.$store.state.user_contact = null;
          this.$store.state.user_contact_name = null;
          this.$store.state.totalPages = data.response.pagination.max_page;
          this.$store.state.pet_wizardProducts_items = data.response.items;
          this.$store.state.pet_wizardProducts_suggested_items =
            data.response.suggested_items;
          this.$store.state.wizard_data = data.petDetails.petwizard_details;
          this.$store.state.pet_id = data.petDetails.id;
          this.$store.state.pet_name = name;
          this.$store.state.occasion_id = occasion_name;
          this.$store.state.wizard_type = "Pet";
          const petWizData = {
            petoccasion: this.petoccasion,
            age: this.age,
            gender: this.gender,
            species: this.species,
            breed: this.breed,
            weight: this.weight,
            name: this.name,
            image: this.image,
            price: this.price,
            pets: this.pets,
            petId: data.petDetails.id,
            pet_id: data.petDetails.id,
          };
          this.$store.state.petWizData = petWizData;
          this.$store.state.petWizardData = {
            occasion: this.petoccasion.id,
            breed: this.breed.id,
            gender: this.gender,
            priceRange_from_price: this.price.from_price,
            priceRange_to_price: this.price.to_price,
            type: this.species.id,
            ageRange: this.age.id,
            image: image,
            weight: this.weight,
            name: this.name,
            user_id: userdata_array.user.id,
            access_type: "web",
            pet_id: petId,
            countryCode: countryCode,
          };
          this.$router.replace({
            name: "PetproductView",
            params: {
              id,
            },
          });
          this.closeModal();
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    continueToPet() {
      this.showContinue = false;
      this.checkPets();
    },
    continueToOccasion() {
      this.showContinue = false;
      this.showOccasion = true;
    },
    speciesToBreed(species) {
      this.species = species;
      this.showSpecies = false;
      this.showBreed = true;
    },
    breedTospecies() {
      this.showSpecies = true;
      this.showBreed = false;
    },
    breedToGender(breed) {
      this.breed = breed;
      this.showBreed = false;
      this.showGender = true;
    },
    genderToBreed() {
      this.showBreed = true;
      this.showGender = false;
    },
    ageRangeToGender() {
      this.showGender = true;
      this.showAge = false;
    },
    genderToAgeRange(gender) {
      this.gender = gender;
      this.showGender = false;
      this.showAge = true;
    },
    agerangeToName(age) {
      this.age = age;
      this.showAge = false;
      this.showName = true;
    },
    nameToAge() {
      this.showAge = true;
      this.showName = false;
    },
    weightToName(weight) {
      this.weight = weight;
      this.showWeight = false;
      this.showName = true;
    },
    weightToAge() {
      this.showAge = true;
      this.showWeight = false;
    },
    nameToOccasion(name) {
      this.name = name;
      this.showOccasion = true;
      this.showName = false;
    },
    ocasionToName() {
      this.showOccasion = false;
      this.petList = true;
    },
    ocasionToImage(occasion) {
      this.petoccasion = occasion;
      this.showImage = true;
      this.showOccasion = false;
    },
    ocasionToPrice(occasion) {
      this.petoccasion = occasion;
      this.SelectPricerange = true;
      this.showOccasion = false;
    },
    ImageToOccasion() {
      this.showImage = false;
      this.showOccasion = true;
    },
    ImageToPrice(image) {
      this.image = image;
      this.showImage = false;
      this.SelectPricerange = true;
    },
    preferenceToImage() {
      this.showImage = true;
      this.showPreferance = false;
    },
    preferenceToPrice(selected_preference) {
      this.showPreferance = false;
      this.SelectPricerange = true;
    },
    petToContinue(pet) {
      this.petData = pet;
      this.SetPetData();
      this.petList = false;
      this.showContinue = true;
    },
    priceToproducts(price) {
      this.price = price;
      this.Products();
    },
    priceToPreference() {
      this.SelectPricerange = false;
      this.showOccasion = true;
    },
    petToSpecies() {
      this.petList = false;
      this.showSpecies = true;
    },
    closeModal() {
      this.$bvModal.hide("petwizard-modal");
    },
    resetAll() {
      // this.occasion = null;
      // this.age = null;
      // this.gender = null;
      // this.species = null;
      // this.breed = null;
      // this.price = null;
      // this.weight = null;
      // this.name = null;
      // this.image = null;
      // this.petId =null,
      // this.petoccasion = null;
      // this.showAge = false;
      // this.showOccasion = false;
      // this.showGender = false;
      // this.showBreed = false;
      // this.showWeight = false;
      // this.showImage = false;
      // this.showPreferance = false;
      // this.showSpecies = false;
      // this.petList = false;
      // this.SelectPricerange = false;
      // this.petData = null;
      this.error_msg = null;
      this.loader = false;
      this.petoccasion = null;
      this.age = null;
      this.gender = null;
      this.species = null;
      this.breed = null;
      this.weight = null;
      this.name = null;
      this.selected_preference = null;
      this.image = null;
      this.price = null;
      this.pets = [];
      this.petList = false;
      this.perice = null;
      this.petData = null;
      this.petId = null;
      this.pet_id = null;

      this.showAge = false;
      this.showOccasion = false;
      this.showGender = false;
      this.showSpecies = false;
      this.showBreed = false;
      this.SelectPricerange = false;
      this.showWeight = false;
      this.showName = false;
      this.showImage = false;
      this.showPreferance = false;
      this.showContinue = false;
    },
  },
};
</script>
