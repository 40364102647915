<template>
  <div id="select-image" class="mt-2">
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <h3>UPLOAD PET IMAGE (OPTIONAL)</h3>
        </center>
        <div class="modal-inner">
          <center>
            <div class="row my-4 mx-0">
              <input
                type="file"
                v-on:change="onImageChange"
                class="form-control"
              />
            </div>
          </center>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "AddImage",
  props: ["menu"],
  data() {
    return {
      image: null,
      loader: false,
    };
  },
  methods: {
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    next() {
      this.loader = true;
      this.$store.state.pet_image = this.image;
      this.$emit("ImageToPrice", this.image);
    },
    prev() {
      this.$emit("ImageToOccasion");
    },
  },
};
</script>
